/* Override datepicker styles */
/*suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',*/

.eventspace-autosuggest__container {
  position: relative;
}

.eventspace-autosuggest__suggestions-container--open {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 100;
}

.eventspace-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.eventspace-autosuggest__suggestion {
  cursor: pointer;
}

.eventspace-autosuggest__suggestion:hover {
  text-decoration: underline;
}
