img {
  max-width: 100%;
  width: auto;
}
.related-item {
  margin-bottom: 1rem
}
.related-item img {
  max-height: 50px;
  height: auto;
}

form {
  padding-bottom: 3rem
}

/* UTILITY */
.u-pointer {
  cursor: pointer;
}

.u-hidden {
  display: none!important;
  visibility: hidden;
}
