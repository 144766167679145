/* Override datepicker styles */
.react-datepicker-wrapper {
  display: block;
  width: 100%;
}
.react-datepicker-popper {
  z-index: 10;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__day--outside-month {
  color: #cccccc;
}
