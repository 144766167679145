.dropzone-container {
  padding: 2rem;
  margin-bottom: 1rem;
  border: 2px dashed;
  border-color: grey;
  outline: none;
  transition: border-color .24s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.active-accept {
  border-color: green;
}

.active-reject {
  border-color: red;
}

.active-uploading {
  animation: pulse .48s infinite;
}

@keyframes pulse {
  0% {
    border-color: black;
  }
  100% {
    border-color: green;
  }
}

.dropzone-text, .dropzone-error {
  margin-bottom: 0;
}

.dropzone-error {
  margin-top: 1rem;
  color: red;
}

.media-modal {
  width: 90vw;
  max-width: 1200px;
}
